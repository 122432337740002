<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Nameserver group for new domains</h1>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <!-- <p class="text-overline mb-0">Dynamic DNS</p> -->
                    <p class="mb-0 pb-0">
                        <span>Default nameserver group: {{ newDomainNameserverGroupLabel }}</span>
                        <v-btn class="blue--text no-print" @click="dialogEditNewDomainNameserverGroup = true" icon>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn>
                    </p>
                    <v-dialog v-model="dialogEditNewDomainNameserverGroup" max-width="600">
                        <v-card elevation="4">
                            <v-toolbar flat color="white">
                                <v-toolbar-title>Select nameserver group for new domains</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text class="pa-5">
                                <p>Whenever a new domain is added, we will automatically assign it to nameservers in this group.</p>
                                <p>You can override this setting for a specific domain, edit domain settings.</p>
                                <v-form @submit.prevent="editNewDomainNameserverGroup" @keyup.enter.native="editNewDomainNameserverGroup" class="mt-5">
                                    <v-text-field
                                        v-model="editableNameserverGroupId"
                                        ref="editableNameserverGroupIdInput"
                                        label="Nameserver Group ID"
                                        outlined>
                                    </v-text-field>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="purple white--text" @click="editNewDomainNameserverGroup" :disabled="!isEditNewDomainNameserverGroupFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditNewDomainNameserverGroup = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data: () => ({
        isViewReady: false,

        // generic form controls
        formSubmitTimestamp: null,

        // dynamic dns
        dialogEditNewDomainNameserverGroup: false,
        nameserverGroup: null,
        editableNameserverGroupId: null,
    }),
    computed: {
        newDomainNameserverGroupLabel() {
            return this.nameserverGroup?.label ?? '';
        },
        isEditNewDomainNameserverGroupFormComplete() {
            return typeof this.editableNameserverGroupId === 'string' && this.editableNameserverGroupId !== this.nameserverGroup?.id;
        },
    },
    watch: {
        dialogEditNewDomainNameserverGroup(value) {
            if (value) {
                this.editableNameserverGroupId = this.nameserverGroup?.id;
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('editableNameserverGroupIdInput'); }, 1);
                });
            }
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async editServiceConfig(name, content) {
            try {
                this.$store.commit('loading', { editServiceConfig: true });
                const response = await this.$client.main().system.editSetting({ name, content });
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', message: 'Saved changes' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to save changes' });
                }
                return response?.isEdited ?? false;
            } catch (err) {
                console.error('failed to edit setting', err);
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to save changes' });
                return false;
            } finally {
                this.$store.commit('loading', { editServiceConfig: false });
                this.isViewReady = true;
            }
        },
        async editNewDomainNameserverGroup() {
            if (Number.isInteger(this.formSubmitTimestamp) && this.formSubmitTimestamp + 500 > Date.now()) {
                return;
            }
            this.formSubmitTimestamp = Date.now();
            const isEdited = await this.editServiceConfig('new_domain_nameserver_group_id', this.editableNameserverGroupId);
            if (isEdited) {
                this.loadNameserverGroup(this.editableNameserverGroupId);
            }
            this.dialogEditNewDomainNameserverGroup = false;
        },
        async loadServiceSettingsList() {
            try {
                this.$store.commit('loading', { loadServiceSettingsList: true });
                const response = await this.$client.main().system.getSettingsList();
                if (response?.list) {
                    const settingsMap = {};
                    response.list.forEach((item) => {
                        settingsMap[item.name] = item.content;
                    });
                    if (settingsMap.new_domain_nameserver_group_id) {
                        this.loadNameserverGroup(settingsMap.new_domain_nameserver_group_id);
                    } else {
                        this.nameserverGroup = null;
                    }
                }
            } catch (err) {
                console.error('failed to load service settings', err);
            } finally {
                this.$store.commit('loading', { loadServiceSettingsList: false });
                this.isViewReady = true;
            }
        },
        async loadNameserverGroup(id) {
            try {
                this.$store.commit('loading', { loadNameserverGroup: true });
                const result = await this.$client.main().nameserverGroup.get({ id });
                this.nameserverGroup = result?.item;
            } catch (err) {
                console.error('failed to load new domain nameserver group', err);
            } finally {
                this.$store.commit('loading', { loadNameserverGroup: false });
            }
        },
    },
    mounted() {
        this.loadServiceSettingsList();
    },
};
</script>
